import React, { FormEvent } from 'react';
import { getAuth } from 'firebase/auth';
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';

import { firebaseApp } from '../../firebase';
import { Logo } from '../../components/Logo';

export const SignIn = () => {
  const auth = getAuth(firebaseApp);

  const [signInWithEmailAndPassword, , loading, error] =
    useSignInWithEmailAndPassword(auth);

  // ────────────────────────────────────────────────────────────────────

  const onSignIn = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    const email = formData.get('email') as string;
    const password = formData.get('password') as string;

    if (!email || !password) return;

    await signInWithEmailAndPassword(email, password);
  };

  // ────────────────────────────────────────────────────────────────────

  return (
    <div className="d-flex align-items-center flex-grow-1">
      <div className="container py-5">
        <div className="row">
          <div className="col-md-7 col-lg-5 col-xxl-4 mx-auto">
            <div className="text-center pb-5">
              <Logo width={300} />
            </div>

            <div className="border rounded p-4">
              <form onSubmit={onSignIn}>
                <div className="mb-3">
                  <label htmlFor="formControlEmail" className="form-label">
                    Էլ. փոստ
                  </label>

                  <input
                    id="formControlEmail"
                    name="email"
                    type="email"
                    autoFocus
                    className="form-control form-control-lg"
                    required
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="formControlPassword" className="form-label">
                    Ծածկագիր
                  </label>

                  <input
                    id="formControlPassword"
                    name="password"
                    type="password"
                    className="form-control form-control-lg"
                    required
                  />
                </div>

                <div className="pt-3">
                  <button
                    className="btn btn-lg btn-primary fw-light w-100"
                    type="submit"
                    disabled={loading}
                  >
                    Մուտք գործել
                  </button>
                </div>

                {error?.message ? (
                  <div
                    className="alert alert-danger fw-light text-center mt-4 mb-0"
                    role="alert"
                  >
                    {error.code === 'auth/user-not-found'
                      ? 'Սխալ մուտքային տվյալներ'
                      : error.message}
                  </div>
                ) : null}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
