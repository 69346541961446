import { FormEvent, useCallback, useMemo } from "react";
import { Result, ResultFields } from "../firebase/converters";
import { Timestamp } from "firebase/firestore";

// ────────────────────────────────────────────────────────────────────────────────

export const useResultForm = (props: UseResultFormProps) => {
  const { onSubmit } = props;

  const submitTestResult = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const resultFormEntries = getFormEntries(event.currentTarget);
      const resultDocData = generateResultDocData(resultFormEntries);

      await onSubmit(resultDocData);
    },
    [onSubmit]
  );

  return useMemo(() => ({ submitTestResult }), [submitTestResult]);
};

// ─── UTILS ──────────────────────────────────────────────────────────────────────

function getFormEntries(form: HTMLFormElement) {
  return Array.from(new FormData(form).entries()) as TestResultFormEntries;
}

function generateResultDocData(formEntries: TestResultFormEntries) {
  return formEntries.reduce<TestResultDocData>((data, entry) => {
    const [fieldName, fieldValue] = entry;

    if (!fieldValue) return data;

    return {
      ...data,
      [fieldName]: getFormattedFieldValue(fieldName, fieldValue),
    };
  }, {});
}

function getFormattedFieldValue(fieldName: ResultFields, fieldValue: string) {
  const isDateField = checkIfDateField(fieldName);
  const isCheckboxField = checkIfCheckboxField(fieldName);

  // ─────────────────────────────────────────────────────────────────

  if (isDateField) return getTimestampFromDateValue(fieldName, fieldValue);
  if (isCheckboxField) return getCheckboxValue(fieldValue);
  return fieldValue;
}

function getTimestampFromDateValue(
  fieldName: ResultFields,
  fieldValue: string
) {
  const date = new Date(fieldValue);

  if (fieldName === ResultFields.dateOfBirth) {
    date.setHours(0, 0, 0, 0);
  }

  return Timestamp.fromDate(new Date(date));
}

function getCheckboxValue(fieldValue: string) {
  return fieldValue === "yes";
}

function checkIfDateField(fieldName: ResultFields) {
  return [ResultFields.sampleDate, ResultFields.dateOfBirth].includes(
    fieldName
  );
}

function checkIfCheckboxField(fieldName: ResultFields) {
  return fieldName === ResultFields.isPositive;
}

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type UseResultFormProps = {
  onSubmit:
    | ((docData: TestResultDocData) => void)
    | ((docData: TestResultDocData) => Promise<void>);
};

type TestResultFormEntries = [ResultFields, string][];

type TestResultDocData = Partial<Result>;
