import React from 'react';

export const Spinner = (props: SpinnerProps) => {
  return (
    <div
      className={`spinner-border text-${props.theme ?? 'primary'} m-auto`}
      role="status"
      style={{ width: '3rem', height: '3rem', borderWidth: 2 }}
    >
      <span className="visually-hidden">Loading...</span>
    </div>
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type SpinnerProps = {
  theme?: 'primary' | 'secondary';
};
