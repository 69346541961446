import React from 'react';

export const FormContainer = (props: ResultFormProps) => {
  const { children, title } = props;

  return (
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-md-10 col-lg-8">
          <h2 className="text-center fw-light mb-5">{title}</h2>

          {children}
        </div>
      </div>
    </div>
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type ResultFormProps = {
  title: string;
  children: React.ReactNode;
};
