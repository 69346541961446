import React from "react";

import { FormContainer, ResultForm } from "../../components";
import { useAddDoc, useHandleResultSubmit, useResultForm } from "../../hooks";

export const AddResult = () => {
  const [response, addDoc] = useAddDoc({ collectionPath: "results" });
  const { submitTestResult } = useResultForm({ onSubmit: addDoc });

  useHandleResultSubmit({ responseData: response.data });

  return (
    <FormContainer title="Ավելացնել նոր թեստի պատասխան">
      <ResultForm
        onSubmit={submitTestResult}
        errorMessage={response.error}
        fetching={response.fetching}
        withResetBtn
      />
    </FormContainer>
  );
};
