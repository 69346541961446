import React from "react";
import { FacebookIcon } from "./FacebookIcon";

export const CompanyInfo = (props: CompanyInfoProps) => {
  const { alignment = "start" } = props;

  return (
    <div
      className={`row justify-content-center justify-content-lg-${alignment}`}
    >
      <div className="col-auto">
        <ul className="list-unstyled fw-light">
          <li>🛫 "Զվարթնոց" օդանավակայան</li>
          <li>
            <a
              href="tel:+37411888222"
              className="text-dark text-decoration-none"
            >
              ☎️ +374 41 888 222
            </a>
          </li>
          <li>⏰ Շուրջօրյա 24/7</li>
        </ul>
      </div>

      <div className="col-sm-1 d-none d-sm-block" />

      <div className="col-auto">
        <ul className="list-unstyled fw-light">
          <li>🏥 Ազատության 13/1</li>
          <li>
            <a
              href="tel:+37411888222"
              className="text-dark text-decoration-none"
            >
              ☎️ +374 11 888 222
            </a>
          </li>
          <li>
            <a
              href="mailto:info@doctorlab.am"
              className="text-dark text-decoration-none"
            >
              ✉️ info@doctorlab.am
            </a>
          </li>
        </ul>
      </div>

      <div className={`col-12 text-center text-lg-${alignment} pt-4`}>
        <a
          href="https://www.facebook.com/doctorlab.am"
          target="_blank"
          rel="nofollow noreferrer"
          className="btn btn-primary d-inline-flex align-items-center"
        >
          <FacebookIcon />
          <span className="ms-2">doctorlab.am</span>
        </a>

        <p className="small fw-light text-secondary pt-3">
          Իրավաբանական հասցե` Բուռնազյան 47, 0005, ք. Երևան, ՀՀ
        </p>
      </div>
    </div>
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type CompanyInfoProps = {
  alignment?: "center" | "start";
};
