import React from "react";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import QRCode from "react-qr-code";

import { Result as ResultType, resultsDoc } from "../../firebase/converters";
import { Spinner } from "../../components/Spinner";
import { Logo } from "../../components/Logo";
import { CompanyInfo } from "../../components/CompanyInfo";
import { getLocalDate } from "../../utils";

export const Result = () => {
  const { id = "" } = useParams<{ id: string }>();

  const [result, loading] = useDocumentDataOnce<ResultType>(resultsDoc(id));

  if (loading)
    return (
      <div className="d-flex my-5 py-5">
        <Spinner theme="secondary" />
      </div>
    );

  if (!result) return null;

  const { isPositive } = result;

  const canUseRussian = Boolean(result.name_RU);
  const canUseArmenian = Boolean(result.name_AM);
  const testName = getTestName(result.testType);

  return (
    <div className="result container py-5">
      <div className="row">
        {/* blank header space */}
        <div className="d-none d-print-block col-12" style={{ height: 200 }} />

        <div className="col-lg-6 text-center text-lg-start pb-5 d-print-none">
          <Logo width={200} />
        </div>

        <div className="w-100" />

        <div className="col-12">
          <div className="d-md-none d-print-none">
            <ResultDetails result={result} />

            <div className="text-center pt-4">
              <ResultQR id={result.id} />
            </div>
          </div>

          <table className="table table-borderless d-none d-print-block d-md-block">
            <tbody>
              <tr>
                <td className="w-100">
                  <ResultDetails result={result} />
                </td>

                <td>
                  <ResultQR id={result.id} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="col-12 text-center mt-5 pt-md-2">
          <table className="table table-bordered">
            <tbody>
              <tr className="fw-light small">
                <td>
                  TEST NAME
                  {canUseRussian ? " | НАЗВАНИЕ ТЕСТА" : ""}
                  {canUseArmenian ? " | ԹԵՍՏԻ ԱՆՎԱՆՈՒՄ" : ""}
                </td>
                <td>
                  RESULT
                  {canUseRussian ? " | РЕЗУЛЬТАТ" : ""}
                  {canUseArmenian ? " | ԱՐԴՅՈՒՆՔ" : ""}
                </td>
              </tr>

              <tr className="fs-6">
                <td>{testName}</td>
                <td>
                  {isPositive
                    ? `Positive${canUseRussian ? " | Положительный" : ""}${
                        canUseArmenian ? " | Դրական" : ""
                      }`
                    : `Negative${canUseRussian ? " | Отрицательный" : ""}${
                        canUseArmenian ? " | Բացասական" : ""
                      }`}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="col-12 pt-5 d-print-none">
          <CompanyInfo />
        </div>
      </div>
    </div>
  );
};

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

const ResultDetails = (props: ResultDetailsProps) => {
  const { result } = props;
  const {
    name_EN,
    name_RU,
    name_AM,
    sex,
    dateOfBirth,
    passportID,
    sampleDate,
  } = result;

  const canUseRussian = Boolean(name_RU);
  const canUseArmenian = Boolean(name_AM);
  const testName = getTestName(result.testType);

  return (
    <div className="result-details">
      <section className="mb-3">
        <h6 className="mb-0">
          {name_EN}
          {canUseRussian ? ` | ${name_RU}` : ""}
          {canUseArmenian ? ` | ${name_AM}` : ""}
        </h6>
        <p className="small fw-light mb-0">
          Name, Surname
          {canUseRussian ? ` | Имя, Фамилия` : ""}
          {canUseArmenian ? ` | Անուն, ազգանուն` : ""}
        </p>
      </section>

      <section className="mb-3">
        <h6 className="mb-0">
          {sex === "M" ? `Male` : `Female`}
          {canUseRussian ? (sex === "M" ? ` | Мужской` : ` | Женский`) : ""}
          {canUseArmenian ? (sex === "M" ? ` | Արական` : ` | Իգական`) : ""}
        </h6>
        <p className="small fw-light mb-0">
          Gender
          {canUseRussian ? ` | Пол` : ""}
          {canUseArmenian ? ` | Սեռ` : ""}
        </p>
      </section>

      <section className="mb-3">
        <h6 className="mb-0">{getLocalDate(dateOfBirth)}</h6>
        <p className="small fw-light mb-0">
          Date of birth
          {canUseRussian ? ` | Дата рождения` : ""}
          {canUseArmenian ? ` | Ծննդյան ամսաթիվ` : ""}
        </p>
      </section>

      <section className="mb-3">
        <h6 className="mb-0">{passportID}</h6>
        <p className="small fw-light mb-0">
          Passport ID
          {canUseRussian ? ` | Номер пасспорта` : ""}
          {canUseArmenian ? ` | Անձնագրի համար` : ""}
        </p>
      </section>

      <section className="mb-3">
        <h6 className="mb-0">
          Nasopharyngeal swab
          {canUseRussian ? ` | Мазок из носоглотки` : ""}
          {canUseArmenian ? ` | Քիթըմպանային քսուք` : ""}
        </h6>
        <p className="small fw-light mb-0">
          Test material
          {canUseRussian ? ` | Тестовый материал` : ""}
          {canUseArmenian ? ` | Թեստային նյութ` : ""}
        </p>
      </section>

      <section className="mb-3">
        <h6 className="mb-0">{testName}</h6>
        <p className="small fw-light mb-0">
          Method
          {canUseRussian ? ` | Метод` : ""}
          {canUseArmenian ? ` | Մեթոդ` : ""}
        </p>
      </section>

      <section className="mb-3">
        <h6 className="mb-0">{getLocalDate(sampleDate, true)}</h6>
        <p className="small fw-light mb-0">
          Date of testing
          {canUseRussian ? ` | Дата тестирования` : ""}
          {canUseArmenian ? ` | Թեստավորման ամսաթիվ` : ""}
        </p>
      </section>
    </div>
  );
};

const ResultQR = (props: ResultQrProps) => {
  const { id } = props;

  return <QRCode value={`https://www.doctorlab.am/results/${id}`} size={180} />;
};

// ─── UTILS ──────────────────────────────────────────────────────────────────────

function getTestName(testType: ResultType['testType']) {
    if (testType === 'AG') return 'COVID-19 AG Test';

    if (testType === 'H1N1') return 'A+B (H1N1) Rapid Test';

    return 'nCov-2019 RNA detection (RT PCR)'
}

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type ResultDetailsProps = {
  result: ResultType;
};

type ResultQrProps = {
  id: string;
};
