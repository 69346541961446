import { useCallback, useMemo, useState } from "react";
import {
  addDoc,
  collection,
  DocumentData,
  getFirestore,
} from "firebase/firestore";

import { firebaseApp } from "../firebase";
import { ERROR_MESSAGE_GENERIC } from "../constants";

// ────────────────────────────────────────────────────────────────────────────────

export const useAddDoc = (props: UseAddToDocProps): UseAddToDocReturn => {
  const { collectionPath } = props;

  const [data, setData] = useState<DocumentData>();
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState<ErrorMessage>(null);

  // ────────────────────────────────────────────────────────────────────

  const handleAddDoc = useCallback(
    async (docData: unknown) => {
      setFetching(true);

      try {
        const updatedData = await addDoc(
          collection(getFirestore(firebaseApp), collectionPath),
          docData
        );

        setData(updatedData);
      } catch (error) {
        setError(
          error instanceof Error ? error.message : ERROR_MESSAGE_GENERIC
        );
      }

      setFetching(false);
    },
    [collectionPath]
  );

  // ────────────────────────────────────────────────────────────────────

  return useMemo(
    () => [{ data, fetching, error }, handleAddDoc],
    [handleAddDoc, data, error, fetching]
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type ErrorMessage = string | null;

type UseAddToDocReturn = [
  response: {
    data: DocumentData | undefined;
    fetching: boolean;
    error: string | null;
  },
  handleAddDoc: (docData: unknown) => Promise<void>
];

type UseAddToDocProps = {
  collectionPath: string;
};
