import { ReactNode } from "react";

import { useAuth } from "../context";
import { SignIn } from "../routes";

export function GuardedRoute(props: GuardedRouteProps) {
  const { isLoggedIn, isLoadingUser } = useAuth();

  if (isLoadingUser) return null;
  if (isLoggedIn) return <>{props.children}</>;
  return <SignIn />;
}

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type GuardedRouteProps = {
  children: ReactNode;
};
