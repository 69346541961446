import { BrowserRouter, Route, Routes } from "react-router-dom";

import { AuthProvider } from "./context";
import { AddResult, EditResult, LandingPage, Result, Results } from "./routes";
import { AppWrapper, GuardedRoute } from "./components";

// ────────────────────────────────────────────────────────────────────────────────

export function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AppWrapper />}>
            <Route path="/" element={<LandingPage />} />
            <Route
              path="add"
              element={
                <GuardedRoute>
                  <AddResult />
                </GuardedRoute>
              }
            />
            <Route
              path="edit/:id"
              element={
                <GuardedRoute>
                  <EditResult />
                </GuardedRoute>
              }
            />
            <Route
              path="results"
              element={
                <GuardedRoute>
                  <Results />
                </GuardedRoute>
              }
            />
            <Route path="results/:id" element={<Result />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}
