import { CompanyInfo, Logo } from "../../components";

export const LandingPage = () => {
  return (
    <div className="container text-center my-5 py-5">
      <div className="p-4">
        <Logo width={500} />
      </div>

      <div className="row justify-content-center pt-5">
        <div className="col-md-8 col-lg-7 text-start">
          <CompanyInfo alignment="center" />
        </div>
      </div>
    </div>
  );
};
