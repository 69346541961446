export function getLocalDate(sourceDate: Date, withTime?: Boolean) {
  const month = (sourceDate.getMonth() + 1).toString().padStart(2, "0");
  const date = sourceDate.getDate().toString().padStart(2, "0");
  const year = sourceDate.getFullYear();

  const localDate = `${date}/${month}/${year}`;

  if (withTime) {
    const hours = sourceDate.getHours().toString().padStart(2, "0");
    const minutes = sourceDate.getMinutes().toString().padStart(2, "0");
    const seconds = sourceDate.getSeconds().toString().padStart(2, "0");

    return `${localDate} ${hours}:${minutes}:${seconds}`;
  }

  return localDate;
}

export function convertDateToFormValue(sourceDate: Date, withTime?: Boolean) {
  const month = (sourceDate.getMonth() + 1).toString().padStart(2, "0");
  const date = sourceDate.getDate().toString().padStart(2, "0");
  const year = sourceDate.getFullYear();

  const localDate = `${year}-${month}-${date}`;

  if (withTime) {
    const hours = sourceDate.getHours().toString().padStart(2, "0");
    const minutes = sourceDate.getMinutes().toString().padStart(2, "0");
    const seconds = sourceDate.getSeconds().toString().padStart(2, "0");

    return `${localDate}T${hours}:${minutes}:${seconds}`;
  }

  return localDate;
}

export function getCurrentDateValue() {
  const currentDate = new Date();
  const timeZoneOffset = currentDate.getTimezoneOffset() * 60000;

  return new Date(currentDate.getTime() - timeZoneOffset)
    .toISOString()
    .substring(0, 16);
}

// ────────────────────────────────────────────────────────────────────────────────

export function openWindow(path: string) {
  const width = 992;
  const height = 700;
  const top = window.screen.height / 2 - height / 2;
  const left = window.screen.width / 2 - width / 2;

  window.open(
    `${window.location.origin}${path}`,
    "_blank",
    `toolbar=0,location=0,menubar=0,top=${top},left=${left},width=${width},height=${height}`
  );
}
