import React, { FormEventHandler } from "react";
import { Result, ResultFields } from "../firebase/converters";

import { convertDateToFormValue, getCurrentDateValue } from "../utils";
import { useNavigate } from "react-router-dom";

export const ResultForm = (props: ResultFormProps) => {
  const {
    onSubmit,
    errorMessage,
    fetching,
    defaultValues,
    withResetBtn,
    withCancelBtn,
  } = props;
  const navigate = useNavigate();

  return (
    <form autoComplete="off" onSubmit={onSubmit}>
      <div className="bg-light bg- rounded rounded-md mb-4 p-3">
        <h4 className="fw-light mb-4">Անձնական տվյլաներ</h4>

        <div className="mb-4">
          <label htmlFor="formControlNameEn" className="form-label">
            🇺🇸 Name, Surname
          </label>

          <input
            id="formControlNameEn"
            name={ResultFields.name_EN}
            type="text"
            autoFocus
            className="form-control form-control-lg"
            required
            defaultValue={defaultValues?.name_EN}
          />
        </div>

        <div className="mb-4">
          <label htmlFor="formControlNameRu" className="form-label">
            🇷🇺 Имя, Фамилия
          </label>

          <input
            id="formControlNameRu"
            name={ResultFields.name_RU}
            type="text"
            className="form-control form-control-lg"
            defaultValue={defaultValues?.name_RU}
          />
        </div>

        <div className="mb-4">
          <label htmlFor="formControlNameAm" className="form-label">
            🇦🇲 Անուն, ազգանուն
          </label>

          <input
            id="formControlNameAm"
            name={ResultFields.name_AM}
            type="text"
            className="form-control form-control-lg"
            defaultValue={defaultValues?.name_AM}
          />
        </div>

        <div className="mb-4">
          <label htmlFor="formControlPassportID" className="form-label">
            Անձնագրի համար
          </label>

          <input
            id="formControlPassportID"
            name={ResultFields.passportID}
            type="text"
            className="form-control form-control-lg"
            required
            defaultValue={defaultValues?.passportID}
          />
        </div>

        <div className="mb-4">
          <label htmlFor="formControlBirthday" className="form-label">
            Ծննդյան ամսաթիվ
          </label>

          <input
            id="formControlBirthday"
            name={ResultFields.dateOfBirth}
            type="date"
            className="form-control form-control-lg"
            required
            defaultValue={
              defaultValues?.dateOfBirth
                ? convertDateToFormValue(defaultValues?.dateOfBirth)
                : ""
            }
            max={convertDateToFormValue(new Date())}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="formControlSex" className="form-label">
            Սեռ
          </label>

          <select
            id="formControlSex"
            className="form-select form-select-lg"
            required
            name={ResultFields.sex}
            defaultValue={defaultValues?.sex}
          >
            <option value="M">Արական</option>
            <option value="F">Իգական</option>
          </select>
        </div>
      </div>

      <div className="bg-light rounded rounded-md mb-4 p-3">
        <h4 className="fw-light mb-4">Թեստ</h4>

        <div className="mb-4">
          <label htmlFor="formControlSampleDate" className="form-label">
            Թեստավորման ամսաթիվ
          </label>

          <input
            id="formControlSampleDate"
            name={ResultFields.sampleDate}
            type="datetime-local"
            defaultValue={
              defaultValues?.sampleDate
                ? convertDateToFormValue(defaultValues?.sampleDate, true)
                : getCurrentDateValue()
            }
            required
            className="form-control form-control-lg"
          />
        </div>

        <div className="mb-3">
          <label htmlFor="formControlType" className="form-label">
            Թեստի տեսակ
          </label>

          <select
            id="formControlType"
            className="form-select form-select-lg"
            required
            name={ResultFields.testType}
            defaultValue={defaultValues?.testType ?? "AG"}
          >
            <option value="AG">AG</option>
            <option value="PCR">PCR</option>
            <option value="H1N1">A+B (H1N1) Rapid Test</option>
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="formControlIsPositive" className="form-label">
            Թեստի պատասխան
          </label>

          <select
            id="formControlIsPositive"
            className="form-select form-select-lg"
            required
            name={ResultFields.isPositive}
            defaultValue={defaultValues?.isPositive ? "yes" : "no"}
          >
            <option value="no">Բացասական</option>
            <option value="yes">Դրական</option>
          </select>
        </div>
      </div>

      <div className="row pt-2">
        <div className="col">
          <button
            className="btn btn-lg btn-success fw-light w-100"
            type="submit"
            disabled={fetching}
          >
            Պահպանել
          </button>
        </div>

        {withResetBtn ? (
          <div className="col-6">
            <button
              className="btn btn-lg btn-danger fw-light w-100"
              type="reset"
              disabled={fetching}
            >
              Մաքրել
            </button>
          </div>
        ) : null}

        {withCancelBtn ? (
          <div className="col-6">
            <button
              className="btn btn-lg btn-secondary fw-light w-100"
              type="button"
              disabled={fetching}
              onClick={() => {
                window.close();
                navigate("/results");
              }}
            >
              Կասեցնել
            </button>
          </div>
        ) : null}
      </div>

      {errorMessage ? (
        <div
          className="alert alert-danger fw-light text-center mt-4 mb-0"
          role="alert"
        >
          {errorMessage}
        </div>
      ) : null}
    </form>
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type ResultFormProps = {
  onSubmit: FormEventHandler<HTMLFormElement>;
  fetching: boolean;
  errorMessage?: string | null;
  defaultValues?: Partial<Result>;
  withResetBtn?: boolean;
  withCancelBtn?: boolean;
};
