import { Outlet } from "react-router-dom";

import { useAuth } from "../context";
import { Navbar } from "./Navbar";
import { FullScreenSpinner } from "./FullScreenSpinner";

export const AppWrapper = () => {
  const { isLoadingUser } = useAuth();

  if (isLoadingUser) return <FullScreenSpinner />;

  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar />
      <Outlet />
    </div>
  );
};
