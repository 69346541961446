import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DocumentData } from "firebase/firestore";

import { openWindow } from "../utils";

// ────────────────────────────────────────────────────────────────────────────────

export const useHandleResultSubmit = (props: UseHandleResultSubmitProps) => {
  const { responseData } = props;
  const navigate = useNavigate();

  useEffect(() => {
    if (!responseData) return;

    const { id } = responseData;

    openWindow(`/results/${id}`);
    navigate("/results");
  }, [navigate, responseData]);
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type UseHandleResultSubmitProps = {
  responseData?: DocumentData | null;
};
