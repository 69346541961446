import React, { useState } from "react";
import { Link, NavLink, useMatch } from "react-router-dom";

import { Logo } from "./Logo";
import { useAuth } from "../context";

export const Navbar = () => {
  const { isLoggedIn } = useAuth();
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const isResultRoute = useMatch("results/:id");
  const isEditRoute = useMatch("edit");
  const shouldShowNavbar = isLoggedIn && !isEditRoute && !isResultRoute;

  // ─── HELPERS ────────────────────────────────────────────────────────

  const toggleMobileNavMenu = () => {
    setIsMobileNavOpen((current) => !current);
  };

  // ────────────────────────────────────────────────────────────────────

  if (!shouldShowNavbar) return null;

  return (
    <nav className="navbar navbar-main navbar-light bg-light d-print-none">
      <div className="container py-2">
        <NavbarBrand />

        <div className="dropdown d-md-none ms-auto">
          <button
            className="btn btn-success dropdown-toggle fw-light"
            type="button"
            id="navbarMobileControlsDropdown"
            data-bs-toggle="dropdown"
            aria-expanded={isMobileNavOpen ? "true" : "false"}
            onClick={toggleMobileNavMenu}
          >
            Ավելին
          </button>

          <NavbarDropdownNavMenu
            show={isMobileNavOpen}
            onClose={toggleMobileNavMenu}
          />
        </div>

        <NavbarNavMenu />
      </div>
    </nav>
  );
};

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

const NavbarBrand = () => {
  const isMainRoute = useMatch("/");
  const shouldShowNavbarLogo = !isMainRoute;

  if (!shouldShowNavbarLogo) return null;

  return (
    <Link to="/">
      <Logo height={45} />
    </Link>
  );
};

const NavbarDropdownNavMenu = (props: NavbarDropdownNavMenuProps) => {
  const { logout } = useAuth();
  const { show, onClose } = props;

  if (!show) return null;

  return (
    <ul
      className="dropdown-menu dropdown-menu-end"
      aria-labelledby="navbarMobileControlsDropdown"
      onClick={onClose}
    >
      {NAVBAR_LINKS.map((linkProps) => (
        <li key={linkProps.to} className="list-inline-item">
          <NavbarDropdownLink key={linkProps.to} {...linkProps} />
        </li>
      ))}

      <li>
        <button onClick={logout} className="dropdown-item text-danger fw-light">
          Դուրս գալ
        </button>
      </li>
    </ul>
  );
};

const NavbarDropdownLink = (props: NavbarLink) => {
  const { to, label } = props;

  return (
    <Link to={to} className="dropdown-item fw-light">
      {label}
    </Link>
  );
};

const NavbarNavMenu = () => {
  const { logout } = useAuth();

  return (
    <ul className="list-inline d-none d-md-inline-block mb-0 ms-auto">
      {NAVBAR_LINKS.map((linkProps) => (
        <li key={linkProps.to} className="list-inline-item">
          <NavbarNavLink key={linkProps.to} {...linkProps} />
        </li>
      ))}

      <li className="list-inline-item">
        <button onClick={logout} className="btn btn-danger fw-light">
          Դուրս գալ
        </button>
      </li>
    </ul>
  );
};

const NavbarNavLink = (props: NavbarLink) => {
  const { to, label, theme = "primary" } = props;

  return (
    <NavLink to={to} className={`btn fw-light btn-${theme ?? "primary"}`}>
      {label}
    </NavLink>
  );
};

// ─── CONSTANTS ──────────────────────────────────────────────────────────────────

const NAVBAR_LINKS: NavbarLink[] = [
  { label: "+ Ավելացնել", to: "/add", theme: "success" },
  { label: "Թեստեր", to: "/results", theme: "secondary" },
];

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type NavbarDropdownNavMenuProps = {
  show: boolean;
  onClose: () => void;
};

type NavbarLink = {
  to: string;
  label: string;
  theme?: string;
};
