import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { signOut, User } from "@firebase/auth";

import { firebaseAuth } from "../../firebase";

const AuthContext = createContext<AuthContextValue | undefined>(undefined);

export function AuthProvider(props: AuthProviderProps) {
  const [user, isLoadingUser] = useAuthState(firebaseAuth);
  const isLoggedIn = Boolean(user);

  const logout = useCallback(() => {
    signOut(firebaseAuth);
  }, []);

  const value = useMemo(
    () => ({ user, isLoadingUser, isLoggedIn, logout }),
    [user, isLoadingUser, isLoggedIn, logout]
  );

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  return context;
}

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type AuthProviderProps = {
  children: ReactNode;
};

type AuthContextValue = {
  user: User | null | undefined;
  isLoadingUser: boolean;
  isLoggedIn: boolean;
  logout: () => void;
};
