import firestore, {
  collection,
  doc,
  getFirestore,
  query,
  where,
} from "firebase/firestore";

import { firebaseApp } from "./firebase";

// ─── RESULTS ────────────────────────────────────────────────────────────────────

const resultConverter: firestore.FirestoreDataConverter<Result> = {
  toFirestore(result) {
    return result;
  },

  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);

    return {
      id: snapshot.id,
      name_AM: data["name_AM"],
      name_RU: data["name_RU"],
      name_EN: data["name_EN"],
      dateOfBirth: data["dateOfBirth"].toDate(),
      passportID: data["passportID"],
      sex: data["sex"],
      sampleDate: data["sampleDate"].toDate(),
      isPositive: data["isPositive"],
      testType: data["testType"],
    };
  },
};

export const resultsCollection = collection(
  getFirestore(firebaseApp),
  "results"
).withConverter(resultConverter);

export const resultsCollectionForDate = (date: Date) => {
  const normalizedDate = new Date(date.getTime());
  normalizedDate.setHours(0, 0, 0, 0);

  const nextDay = new Date(normalizedDate.getTime());
  nextDay.setDate(nextDay.getDate() + 1);

  return query(
    resultsCollection,
    where("sampleDate", ">=", normalizedDate),
    where("sampleDate", "<", nextDay)
  );
};

export const resultsDoc = (id: string) =>
  doc(getFirestore(firebaseApp), "results", id).withConverter(resultConverter);

// ─── MODELS ─────────────────────────────────────────────────────────────────────

export type Result = {
  id: string;
  name_AM: string;
  name_RU: string;
  name_EN: string;
  dateOfBirth: Date;
  passportID: string;
  sex: "M" | "F";
  sampleDate: Date;
  isPositive: Boolean;
  testType: "AG" | "PCR" | 'H1N1';
};

export enum ResultFields {
  name_AM = "name_AM",
  name_RU = "name_RU",
  name_EN = "name_EN",
  dateOfBirth = "dateOfBirth",
  passportID = "passportID",
  sex = "sex",
  sampleDate = "sampleDate",
  isPositive = "isPositive",
  testType = "testType",
}
