import React, { FormEvent, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Timestamp, updateDoc } from "firebase/firestore";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import {
  Result as ResultType,
  Result,
  resultsDoc,
} from "../../firebase/converters";
import { ResultForm } from "../../components/ResultForm";
import { FormContainer } from "../../components/FormContainer";
import { Spinner } from "../../components/Spinner";

export const EditResult = () => {
  const { id = "" } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [result, loading] = useDocumentDataOnce<ResultType>(resultsDoc(id));
  const [editingResult, setEditingResult] = useState(false);
  const [editResultErrorMessage, setEditResultErrorMessage] = useState("");

  if (loading)
    return (
      <div className="d-flex my-5 py-5">
        <Spinner theme="secondary" />
      </div>
    );

  if (!result) return null;

  // ─── HELPERS ────────────────────────────────────────────────────────

  const editResult = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setEditingResult(true);

    const form = event.currentTarget;
    const formEntries = Array.from(new FormData(form).entries());

    const docData = formEntries.reduce<Partial<Result>>(
      (data, [name, value]) => {
        const castedName = name as keyof Result;
        const castedValue = value as string;

        // ─── DATE ───────────────────────────────────────────────────────────

        if (castedName === "dateOfBirth" || castedName === "sampleDate") {
          const date = new Date(castedValue);
          if (castedName === "dateOfBirth") date.setHours(0, 0, 0, 0);

          const timestamp = Timestamp.fromDate(new Date(date));
          return { ...data, [castedName]: timestamp };
        }

        // ─── BOOLEANS ───────────────────────────────────────────────────────

        if (castedName === "isPositive")
          return { ...data, [castedName]: value === "yes" };

        // ────────────────────────────────────────────────────────────────────

        return { ...data, [castedName]: value };
      },
      {}
    );

    try {
      await updateDoc(resultsDoc(id), docData);

      window.close();
      navigate(`/results`);
    } catch (error) {
      const genericErrorMessage =
        "Տեղի է ունեցել ծրագրային սխալ։ Խնդրում ենք փորձել ավելի ուշ։";

      setEditResultErrorMessage(
        error instanceof Error ? error.message : genericErrorMessage
      );
    }

    setEditingResult(false);
  };

  // ────────────────────────────────────────────────────────────────────

  return (
    <FormContainer title="Ձևափոխել թեստի պատասխանը">
      <ResultForm
        onSubmit={editResult}
        errorMessage={editResultErrorMessage}
        fetching={editingResult}
        withCancelBtn
        defaultValues={result}
      />
    </FormContainer>
  );
};

// ─── UTILS ──────────────────────────────────────────────────────────────────────
