import React, {
  ChangeEventHandler,
  MouseEventHandler,
  useCallback,
  useState,
} from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";

import {
  resultsCollectionForDate,
  resultsDoc,
} from "../../firebase/converters";
import { convertDateToFormValue, getLocalDate, openWindow } from "../../utils";
import { Spinner } from "../../components/Spinner";
import { deleteDoc } from "firebase/firestore";
import { useAuth } from "../../context/Auth";

export const Results = () => {
  const { user } = useAuth();

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [activeId, setActiveId] = useState("");
  const [results, loading, error] = useCollectionData(
    resultsCollectionForDate(selectedDate)
  );
  const canRemoveDocs =
    user?.uid === "NWKMwpRwm3MvPam1jn8rYPvq5hz2" ||
    user?.uid === "0ADob7v79lR2XOkRLJxzRD9g1I93";

  // ─── HELPERS ────────────────────────────────────────────────────────

  const changeDate: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const date = event.currentTarget.valueAsDate;
      if (date) setSelectedDate(date);
    },
    []
  );

  const removeRecord: MouseEventHandler<HTMLButtonElement> = async (event) => {
    if (!canRemoveDocs) return;

    const id = event.currentTarget.dataset["id"] ?? "";

    const condition = window.confirm(
      "համոզվա՞ծ եք, որ ցանկանում եք ջնջել տվյալ թեստի պատասխանը։"
    );

    if (!condition) return;

    setActiveId(id);

    try {
      await deleteDoc(resultsDoc(id));
    } catch {
      alert("Տեղի է ունեցել ծրագրային սխալ։ Խնդրում ենք փորձել ավելի ուշ։");
    }

    setActiveId("");
  };

  // const editRecord: MouseEventHandler<HTMLButtonElement> = (event) => {
  //   const id = event.currentTarget.dataset['id'] ?? '';
  //   openWindow(`/edit/${id}`);
  // };

  const openResult: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();

    const id = event.currentTarget.dataset["id"] ?? "";
    openWindow(`/results/${id}`);
  };

  // ────────────────────────────────────────────────────────────────────

  if (error) return null;

  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-auto mb-4 px-xl-0">
          <input
            type="date"
            className="form-control form-control-lg"
            value={convertDateToFormValue(selectedDate)}
            onChange={changeDate}
            max={convertDateToFormValue(new Date())}
          />
        </div>
      </div>

      {loading ? (
        <div className="d-flex my-5 py-5">
          <Spinner theme="secondary" />
        </div>
      ) : (
        <>
          {results && results?.length > 0 ? (
            <ul className="list-unstyled list-results">
              <li className="row list-results-header d-none d-xl-flex">
                <div className="col-xl-auto list-results__name">
                  Անուն, ազգանուն
                </div>
                <div className="col-xl-1">Սեռ</div>
                <div className="col-xl-auto list-results__birthday">
                  Ծննդյան ամսաթիվ
                </div>
                <div className="col-xl-2">Անձնագրի համար</div>
                <div className="col-xl-2">Թեստի ամսաթիվ</div>
                <div className="col-xl-2">Պատասխան</div>
                <div className="col-xl" />
              </li>

              {results.map((result) => {
                return (
                  <li
                    key={result.id}
                    className="row fw-light list-results-row py-2"
                  >
                    <div
                      className="col-xl-auto text-truncate list-results__name"
                      title={result.name_EN}
                    >
                      <a href="#" onClick={openResult} data-id={result.id}>
                        {result.name_EN}
                      </a>
                    </div>

                    <div className="col-xl-1 d-none d-xl-block">
                      {result.sex === "F" ? "Իգական" : "Արական"}
                    </div>
                    <div className="col-xl-auto list-results__birthday">
                      {getLocalDate(result.dateOfBirth)}
                    </div>
                    <div className="col-xl-2">{result.passportID}</div>
                    <div className="col-xl-2">
                      {getLocalDate(result.sampleDate, true)}
                    </div>
                    <div
                      className={`col-xl-2 ${
                        result.isPositive ? "text-danger" : "text-success"
                      }`}
                    >
                      {result.isPositive ? "դրական" : "բացասական"}
                    </div>

                    <div className="col-xl justify-content-xl-center pt-2 pt-xl-0 px-xl-0">
                      <div className="btn-group" role="group">
                        {/*<button*/}
                        {/*  type="button"*/}
                        {/*  className="btn btn-sm btn-warning"*/}
                        {/*  data-id={result.id}*/}
                        {/*  disabled={activeId === result.id}*/}
                        {/*  onClick={editRecord}*/}
                        {/*>*/}
                        {/*  ✎*/}
                        {/*</button>*/}

                        {canRemoveDocs ? (
                          <button
                            type="button"
                            className="btn btn-sm btn-danger"
                            data-id={result.id}
                            disabled={activeId === result.id}
                            onClick={removeRecord}
                          >
                            X
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : (
            <p className="text-center fw-light fs-2 py-5">
              Ընտրված օրվա համար տվյալներ չեն գտնվել
            </p>
          )}
        </>
      )}
    </div>
  );
};
