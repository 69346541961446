import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBnLTai43ci0c425Y-kK6G59iLyxdwcgpY",
  authDomain: "doctor-lab-51541.firebaseapp.com",
  projectId: "doctor-lab-51541",
  storageBucket: "doctor-lab-51541.appspot.com",
  messagingSenderId: "172022117953",
  appId: "1:172022117953:web:85ad733d0809e27cf478c0",
};

export const firebaseApp = initializeApp(firebaseConfig);

export const firebaseAuth = getAuth(firebaseApp);
